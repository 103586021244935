import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
export const query = graphql`
  query {
    nordstrom: file(relativePath: { eq: "Nordstrom/featured-image.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom1: file(relativePath: { eq: "Nordstrom/nordstrom-01.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom2: file(relativePath: { eq: "Nordstrom/nordstrom-02.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom3: file(relativePath: { eq: "Nordstrom/nordstrom-03.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom4: file(relativePath: { eq: "Nordstrom/nordstrom-04.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom5: file(relativePath: { eq: "Nordstrom/nordstrom-05.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom6: file(relativePath: { eq: "Nordstrom/nordstrom-06.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nordstrom7: file(relativePath: { eq: "Nordstrom/nordstrom-07.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.nordstrom1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.nordstrom.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.nordstrom2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nordstrom3.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The dashboard shows the latest photoshoots uploaded to the Nordstrom system. The platform is powered by Adobe's AEM solution, housing hundreds of thousands of assets. The metadata tool takes tags and places the images in the correct product pages - minimizing production times and automating most of the process.</p>
      <ImageContainer classes="large" fluid={props.data.nordstrom4.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nordstrom5.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The job view shows all photo assets related to the photoshoot or job. The number of images per shoot varies from shoot to shoot, the user interface was designed with that in mind. Multi-selection allows the user to quickly tag data. In the above image - when a user selects the 2 outfits. Every piece of clothing associated with that outfit is displayed. From there - selecting the featured product checkbox can update the product page carousel.</p>
      <ImageContainer classes="large" fluid={props.data.nordstrom6.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="large" fluid={props.data.nordstrom7.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      